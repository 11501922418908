import _ from 'lodash';

Foundation.Abide.defaults.patterns['dashes_only'] = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
Foundation.Abide.defaults.patterns['name'] = /^[A-Za-zwáéíóäëiöúàèììù\s]+$/;
Foundation.Abide.defaults.patterns['todo'] = /.*/;

$(document).foundation();

$(function(){

	/*if (isMobile.tablet || isMobile.phone) {
		$('.contactanos').addClass('contactanos--bottom');
	} else {
		$('.contactanos').removeClass('contactanos--bottom');
	}*/

	/*$('.modal').on('submit',function(event){
		event.preventDefault();
		var $data = $('.modal').find('form').serialize();
		$.ajax({
			type: "POST",
			url: "include/mailer.php",
			data: $data,
			beforeSend: function(){
				$('.modal').find('form').find('button').prop('disabled', true);
				$('.modal').find('form').find('button').html('Enviando mensaje...');
			},
			success: function(datos){
				if (datos != 0) {
					$('.modal').find('form').find('button').css('background', '#79c11a');
					$('.modal').find('form').find('button').html('Mensaje enviado con éxito!');
				} else {
					$('.modal').find('form').find('button').css('background', '#f10420');
					$('.modal').find('form').find('button').html('Ha ocurrido un error!');
				}
				resetForm();
			},
			error: function(){
				$('.modal').find('form').find('button').css('background', '#f10420');
				$('.modal').find('form').find('button').html('Ha ocurrido un error!');
				resetForm();
			}
		});
	});*/

	$('.header__menu a').on('click', function(event){
		event.preventDefault();
		if ($(this).attr('href') != '#') {
			$('html, body').animate({
				scrollTop: $($(this).attr('href')).offset().top
			}, 500);
		}
	});

	$('.mobile a').on('click', function(event){
		event.preventDefault();
		if ($(this).attr('href') != '#') {
			$('.off-canvas').foundation('close');
			$('html, body').animate({
				scrollTop: $($(this).attr('href')).offset().top
			}, 500);
		}
	});

	var $video = plyr.setup();
	var $player = plyr.get('.js-player');

	var $b1 = $('.bloques').find('.col').eq(0);
	var $b2 = $('.bloques').find('.col').eq(1);
	var $b3 = $('.bloques').find('.col').eq(2);
	var $b4 = $('.bloques').find('.col').eq(3);
	var $b5 = $('.bloques').find('.col').eq(4);
	var $b6 = $('.bloques').find('.col').eq(5);

	var $isVideoUp = false;

	var $videoModal = $('.video-modal');
	var $closeVideoModal = $('.video-modal__close');

	$b1.on('click', function(event){
		event.preventDefault();

		$video[0].source({
			type:       'video',
			title:      'Example title',
			sources: [{
				src:    'vids/metso.mp4',
				type:   'video/mp4'
			}]
		});

		$videoModal.fadeIn('medium');
		$isVideoUp = true;

		$video.forEach(function(instance) {
			instance.play();
		});
	});

	$b2.on('click', function(event){
		event.preventDefault();

		$video[0].source({
			type:       'video',
			title:      'Example title',
			sources: [{
				src:    'vids/digital.mp4',
				type:   'video/mp4'
			}]
		});

		$videoModal.fadeIn('medium');
		$isVideoUp = true;

		$video.forEach(function(instance) {
			instance.play();
		});
	});

	$b3.on('click', function(event){
		event.preventDefault();

		$video[0].source({
			type:       'video',
			title:      'Example title',
			sources: [{
				src:    'vids/totem.mp4',
				type:   'video/mp4'
			}]
		});

		$videoModal.fadeIn('medium');
		$isVideoUp = true;

		$video.forEach(function(instance) {
			instance.play();
		});
	});

	$b4.on('click', function(event){
		event.preventDefault();

		$video[0].source({
			type:       'video',
			title:      'Example title',
			sources: [{
				src:    'vids/komatsu.mp4',
				type:   'video/mp4'
			}]
		});

		$videoModal.fadeIn('medium');
		$isVideoUp = true;

		$video.forEach(function(instance) {
			instance.play();
		});
	});

	$b5.on('click', function(event){
		event.preventDefault();

		$video[0].source({
			type:       'video',
			title:      'Example title',
			sources: [{
				src:    'vids/lab.mp4',
				type:   'video/mp4'
			}]
		});

		$videoModal.fadeIn('medium');
		$isVideoUp = true;

		$video.forEach(function(instance) {
			instance.play();
		});
	});

	$b6.on('click', function(event){
		event.preventDefault();

		$video[0].source({
			type:       'video',
			title:      'Example title',
			sources: [{
				src:    'vids/gestorvr.mp4',
				type:   'video/mp4'
			}]
		});

		$videoModal.fadeIn('medium');
		$isVideoUp = true;

		$video.forEach(function(instance) {
			instance.play();
		});
	});

	$closeVideoModal.on('click', function(event){
		event.preventDefault();
		$video.forEach(function(instance) {
			instance.stop();
		});
		$videoModal.fadeOut('medium');
		$isVideoUp = false;
	});

	$videoModal.find('.video-modal__wrapper').on('click', function(event){
		event.preventDefault();
	});

	var $slideButtons = $('.buttons').find('a');

	var $slick = $('.slide__slick');
	$slick.on('init', function(){
		//$('#bar').addClass('animation');
	});
	$slick.on('afterChange', function(){
		$('#bar').removeClass('animation').delay(1).queue(function(){
			$('#bar').addClass('animation').dequeue();
		});
	});
	$slick.slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		dots: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
	});

	$slideButtons.each(function(index, button){
		$(this).on('click', function(event){
			event.preventDefault();
			$slick.slick('slickGoTo', index);
		});
	});

	reduceSlide();
	resizeAction();
	$(window).on('resize', _.throttle(function(){
		reduceSlide();
		resizeAction();
		$map.fitLatLngBounds(bounds);
	}, 100));

	function resizeAction(){
		var $slideWidth = $slick.width();
		var $windowWidth = $(window).width();
		var $deff = Math.abs($slideWidth - $windowWidth)/2;
		$('.slide').css('left',$deff*-1);
	}

	function reduceSlide(){
		if ($(window).width() > 1500) {
			$('.slide').css('width',1920);		
		} else if($(window).width() < 1500) {
			$('.slide').css('width',1500);
		}
	}

	var $marcas = $('.marcas__slick');
	$marcas.slick({
		infinite: true,
		autoplay: false,
		autoplaySpeed: 3000,
		arrows: true,
		dots: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
		slidesToShow: 8,
  		slidesToScroll: 1,
  		responsive: [
      	{
      		breakpoint: 1500,
      		settings: {
      			slidesToShow: 6
      		}
      	},
      	{
      		breakpoint: 1200,
      		settings: {
      			slidesToShow: 5
      		}
      	},
      	{
      		breakpoint: 1000,
      		settings: {
      			slidesToShow: 4
      		}
      	},
      	{
      		breakpoint: 750,
      		settings: {
      			slidesToShow: 3
      		}
      	},
      	{
      		breakpoint: 550,
      		settings: {
      			slidesToShow: 1
      		}
      	},
      	]
	});

	/*var $slick3 = $('.productos-slide__slick');
	$slick3.slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: true,
		dots: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
		slidesToShow: 4,
  		slidesToScroll: 1,
  		prevArrow:"<img class='a-left control-c prev slick-prev' src='img/larrow.jpg'>",
      	nextArrow:"<img class='a-right control-c next slick-next' src='img/rarrow.jpg'>",
      	responsive: [
      	{
      		breakpoint: 1300,
      		settings: {
      			slidesToShow: 3
      		}
      	},
      	{
      		breakpoint: 960,
      		settings: {
      			slidesToShow: 2
      		}
      	},
      	{
      		breakpoint: 620,
      		settings: {
      			slidesToShow: 1
      		}
      	},
      	]
	});*/

	/*var $map = new GMaps({
		div: '.map',
		lat: -37.350493,
		lng: -72.369791,
		zoom: 15,
		scrollwheel: false
	});

	$map.addMarker({
		lat: -37.350493,
		lng: -72.369791,
		title: 'QUALITATCORP',
		infoWindow: {
			content: '<h3>QUALITATCORP</h3>'
		}
	});*/


/*	var $map = new GMaps({
		div: '.map',
		lat: '',
		lng: '',
		zoom: 6,
		scrollwheel: false
	});
	$map.addMarker({
		lat: -36.778132,
		lng: -73.080842,
		title: 'QUALITATCORP',
		infoWindow: {
			content: '<h5>Oficina Concepción</h5>'
		}
	});
	$map.addMarker({
		lat: -33.439484,
		lng: -70.631363,
		title: 'QUALITATCORP',
		infoWindow: {
			content: '<h5>Oficina Santiago</h5>'
		}
	});
	$map.fitLatLngBounds(bounds);*/

	/*var bounds = [];
	var latlng;

	latlng = new google.maps.LatLng(-36.778132,-73.080842);
    bounds.push(latlng);

    latlng = new google.maps.LatLng(-33.439484,-70.631363);
    bounds.push(latlng);

	var $map = new GMaps({
		div: '.contacto__mapa',
		lat: '',
		lng: '',
		zoom: 6,
		scrollwheel: false
	});
	$map.addMarker({
		lat: -36.778132,
		lng: -73.080842,
		title: 'QUALITATCORP',
		infoWindow: {
			content: '<h5>Oficina Concepción</h5>'
		}
	});
	$map.addMarker({
		lat: -33.439484,
		lng: -70.631363,
		title: 'QUALITATCORP',
		infoWindow: {
			content: '<h5>Oficina Santiago</h5>'
		}
	});
	$map.fitLatLngBounds(bounds);*/

	var $contactanos = $('.contactanos');

	$contactanos.on('click', function(event){
		event.preventDefault();
		$('html, body').animate({
			scrollTop: $('#contacto').offset().top
		}, 500);
	});

	var tt = InView(document.querySelector('.header'), function(isInView, data) {
		//&& data.elementOffsetTopInViewHeight < 200
		if (isInView) {
			$('.contactanos').stop().fadeOut('fast');
		} else {
			$('.contactanos').stop().fadeIn('fast').css('display','table');
		}
	});

	var tt2 = InView(document.querySelector('#contacto'), function(isInView, data) {
		//&& data.elementOffsetTopInViewHeight < 200
		if (isInView && data.elementOffsetTopInViewHeight <= 200) {
			$('.contactanos').stop().fadeOut('fast');
		} else {
			$('.contactanos').stop().fadeIn('fast').css('display','table');
		}
	});

	var $contacto = $('.contacto');
	var $contacto2 = $('.contacto2');

	var $resetForm = function(){
		var $timer = setTimeout(function(){
			$contacto.find('input').val('');
			$contacto.find('textarea').val('');
			$contacto.find('button').prop('disabled', false);
			$contacto.find('button').css('background', '#018fc5');
			$contacto.find('button').html('Enviar');
		}, 2000);
	};

	$contacto.on("formvalid.zf.abide", function(ev,frm) {
	
		event.preventDefault();
		var $data = $contacto.find('form').serialize();
		$.ajax({
			type: "POST",
			url: "include/mailer.php",
			data: $data,
			beforeSend: function(){
				$contacto.find('button').prop('disabled', true);
				$contacto.find('button').html('Enviando mensaje...');
			},
			success: function(datos){
				if (datos != 0) {
					$contacto.find('button').css('background', '#79c11a');
					$contacto.find('button').html('Mensaje enviado con éxito!');
				} else {
					$contacto.find('button').css('background', '#f10420');
					$contacto.find('button').html('Ha ocurrido un error!');
				}
				$resetForm();
			},
			error: function(){
				$contacto.find('button').css('background', '#f10420');
				$contacto.find('button').html('Ha ocurrido un error!');
				$resetForm();
			}
		});	

	});

	$contacto2.on("formvalid.zf.abide", function(ev,frm) {
	
		event.preventDefault();
		var $data = $contacto2.find('form').serialize();
		$.ajax({
			type: "POST",
			url: "include/mailer2.php",
			data: $data,
			beforeSend: function(){
				$contacto2.find('button').prop('disabled', true);
				$contacto2.find('button').html('Enviando mensaje...');
			},
			success: function(datos){
				if (datos != 0) {
					$contacto2.find('button').css('background', '#79c11a');
					$contacto2.find('button').html('Mensaje enviado con éxito!');
				} else {
					$contacto2.find('button').css('background', '#f10420');
					$contacto2.find('button').html('Ha ocurrido un error!');
				}
				$resetForm();
			},
			error: function(){
				$contacto2.find('button').css('background', '#f10420');
				$contacto2.find('button').html('Ha ocurrido un error!');
				$resetForm();
			}
		});	

	})

	/*$('a[href*="#"]:not([href="#"])').click(function() {

		$('#offCanvas').foundation('close');

		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 700);
				return false;
			}
		}
		
	});

	$("#scroll-top").hide();
	// fade in #back-top
	$(window).scroll(function () {
		if ($(this).scrollTop() > 300) {
			$('#scroll-top').fadeIn();
		} else {
			$('#scroll-top').fadeOut();
		}
	});
	// scroll body to 0px on click
	$('#scroll-top button').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
	});*/

});